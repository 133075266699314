import { Table } from "react-bootstrap";
const DemoTable = () => {
  return (
    <>
    <Table>
  <tr>
    <td>
       <button>Select</button>
    </td>
    <td>
     <Table>
      <tr>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
      </tr>
     </Table>
    </td>
  </tr>

  <tr>
    <td>
       <button>Select</button>
    </td>
    <td>
     <Table>
      <tr>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
        <td>fdgdf</td>
      </tr>
     </Table>
    </td>
  </tr>
</Table>
    </>
  )
}
export default DemoTable