
import { EventScheduler } from "./event-scheduler";
import "./calendar.css";

const Management = () => {
    return (
        <>
            <EventScheduler />
        </>
    )
}
export default Management